<template>
  <div>
    <detail-template
        type-key="header"
        :topic="sms.topic"
        :detail="sms.detail"
        :end-point="sms.apiEndpoint"
        :parameter-items="sms.parameterItems"
    />
    <detail-template
        :topic="create.topic"
        :detail="create.detail"
        :end-point="create.apiEndpoint"
        :parameter-items="create.parameterItems"
    />
    <CodeTemplate
        @click="sendSMS"
        category="sms_createSMS"
        :data="createData"
        :template-config="codeTemplateConfig.createSMS"
        :result="result"
    />
    <detail-template
        :topic="view.topic"
        :detail="view.detail"
        :end-point="view.apiEndpoint"
        :parameter-items="view.parameterItems"
    />
    <CodeTemplate
        @click="viewSMS"
        category="sms_viewSMS"
        :data="viewData"
        :template-config="codeTemplateConfig.viewSMS"
        :result="resultView"
    />
    <detail-template
        :topic="viewAll.topic"
        :detail="viewAll.detail"
        :end-point="viewAll.apiEndpoint"
    />
    <CodeTemplate
        @click="viewAllSMS"
        :data="viewAllData"
        :template-config="codeTemplateConfig.viewAllSMS"
        :result="resultViewAll"
    />
    <!-- status sms api   -->
    <div>
      <h4 class="card-title">Status SMS API</h4>
      <label>Response status SMS API </label>
      <div>
        <b-table responsive head-variant="light" bordered class="mb-0" :items="statusSmsApiList" :fields="fields">
        </b-table>
      </div>
    </div>
  </div>
</template>

<script>
import CodeTemplate from "../commonAPI/codeTemplate";
import codeTemplateConfig from "./codeTemplateConfig";
import DetailTemplate from "@/views/smsAPI/commonAPI/detailTemplate";
import smsApi from "@/repository/apiSMS";
import alert from "@/common/alert";
import statusSmsApi from "@/views/smsAPI/sms/statusSmsApi.json"

export default {
  name: "smsApi",
  components: {
    DetailTemplate,
    CodeTemplate,
  },
  data() {
    return {
      codeTemplateConfig,
      sms: {
        topic: 'SMS API',
        detail: 'SMS API allows you to send and receive SMS messages to and from any country in the world through a REST API. Each message is identified by a unique random ID so that users can always check the status of a message using the given endpoint.',
        apiEndpoint: '/message-sms',
        parameterItems: [
          {
            parameter: "Authorization",
            required: {statusbg: "success", statustype: "Yes"},
            description: "When calling our API, send your api token with the authentication type set as <span style=\"color:#e74c3c\"><code><span style=\"background-color:#dddddd\">Bearer</span></code></span> (Example: <span style=\"color:#e74c3c\"><code><span style=\"background-color:#dddddd\">Authorization: Bearer {api_token}</span></code></span>)",
          },
          {
            parameter: "Accept",
            required: {statusbg: "success", statustype: "Yes"},
            description: "Set to <span style=\"color:#e74c3c\"><code><span style=\"background-color:#dddddd\">application/json</span></code></span>",
          },
        ],
      },
      create: {
        topic: 'Send outbound SMS',
        detail: 'SMS\'s Programmable SMS API enables you to programmatically send SMS messages from your web application. First, you need to create a new message object. SMS returns the created message object with each request.\n' +
            '\n' +
            'Send your first SMS message with this example request.',
        apiEndpoint: '/message-sms/send',
        parameterItems: [
          {
            parameter: "recipient",
            required: {statusbg: "success", statustype: "Yes"},
            type: "string",
            description: "Number to send message",
          },
          {
            parameter: "sender_name",
            required: {statusbg: "success", statustype: "Yes"},
            type: "string",
            description: "The sender of the message. This can be a telephone number (including country code) or an alphanumeric string. In case of an alphanumeric string, the maximum length is 11 characters.",
          },
          {
            parameter: "message",
            required: {statusbg: "success", statustype: "Yes"},
            type: "string",
            description: "The body of the SMS message.",
          },

        ],
      },
      result: {},
      view: {
        topic: 'View an SMS',
        detail: 'You can use SMS API to retrieve information of an existing inbound or outbound SMS message.\n' +
            'You only need to supply the unique message id that was returned upon creation or receiving.',
        apiEndpoint: '/message-sms/get/<span style="color:#e74c3c">{uid}</span>',
        parameterItems: [
          {
            parameter: "uid",
            required: {statusbg: "success", statustype: "Yes"},
            type: "string",
            description: "CA unique random uid which is created on the SMS platform and is returned upon creation of the object.",
          },
        ],

      },
      resultView: {},
      viewAll: {
        topic: 'View all messages',
        detail: '',
        apiEndpoint: '/message-sms/list',
        parameterItems: [],
      },
      resultViewAll: {},
      createData: {
        fields: [
          {
            label: "recipient",
            field: "recipient",
            placeholder: "Input group recipient",
            // required:"required"
          },
          {
            label: "sender_name",
            field: "sender_name",
            placeholder: "Input sender name",
            // required:"required"
          },
          {
            label: "message",
            field: "message",
            placeholder: "Input message",
          },
        ],
      },
      viewData: {
        fields: [
          {
            label: "uid",
            field: "uid",
            placeholder: "Input uid",
          }
        ],
      },
      viewAllData: {
        fields: [],
      },
      fields: [
        {key: 'code', label: 'CODE'},
        {key: 'description', label: 'Description (EN)',},
        {key: 'descriptionTH', label: 'Description (TH)'},
      ],
      statusSmsApiList: statusSmsApi
    };
  },
  methods: {
    sendSMS(param) {
      this.$swal.fire({
        icon: 'warning',
        title: this.$t('alertSMSApi.confirm'),
        text: this.$t('alertSMSApi.detail'),
        showCloseButton: true,
        showCancelButton: true,
        confirmButtonColor: '#EA5455',
        cancelButtonColor: '#7367F0',
        cancelButtonText: this.$t('button.cancel'),
        confirmButtonText: this.$t('button.send'),
        allowOutsideClick: false
      }).then((result) => {
        if (result.isConfirmed) {
          smsApi.sendSMS(param).then(response => {
            if (response.status === 'success') {
              alert.success(this)
              this.$emit('output')
              this.result = response
            }
          }).catch(err => {
            if (err?.code == "400" && err?.message === "Customer Credit Not Enough") {
              const current_plan_id = localStorage.getItem('current_plan_id')
              alert.creditIsInsufficient(this, err?.message, `/Subscriptions/Package?current=${current_plan_id}`)
            } else {
              alert.fail(this, err?.message)
            }
            this.result = err
          })
        }
      })
    },
    viewSMS(param) {
      smsApi.viewSMS(param).then(response => {
        if (response.status === 'success') {
          alert.success(this)
          this.resultView = response
        }
      }).catch(err => {
        if (err?.code == "400" && err?.message === "Customer Credit Not Enough") {
          const current_plan_id = localStorage.getItem('current_plan_id')
          alert.creditIsInsufficient(this, err?.message, `/Subscriptions/Package?current=${current_plan_id}`)
        } else {
          alert.fail(this, err?.message)
        }
        this.resultView = err
      })
    },
    viewAllSMS(param) {
      smsApi.viewAllSMS(param).then(response => {
        if (response.status === 'success') {
          alert.success(this)
          this.resultViewAll = response
        }
      }).catch(err => {
        if (err?.code == "400" && err?.message === "Customer Credit Not Enough") {
          const current_plan_id = localStorage.getItem('current_plan_id')
          alert.creditIsInsufficient(this, err?.message, `/Subscriptions/Package?current=${current_plan_id}`)
        } else {
          alert.fail(this, err?.message)
        }
        this.resultViewAll = err
      })
    }
  }
};
</script>

