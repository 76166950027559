<template>
  <div id="developer-page">
    <h4 class="card-title">Status Gateway</h4>
    <label>Response status SMS Gateway </label>
    <!--    <h5 class="mt-3 text-primary text-bold ">DTAC</h5>-->
    <b-table responsive sticky-header class="mb-0" :items="dtacList" :fields="fields">
    </b-table>
    <!--    <h5 class="mt-3 text-primary text-bold ">TRUE</h5>-->
    <!--    <b-table responsive sticky-header class="mb-0" :items="trueList" :fields="fields">-->
    <!--    </b-table>-->
    <!--    <h5 class="mt-3 text-primary text-bold ">DR</h5>-->
    <!--    <b-table responsive sticky-header class="mb-0" :items="drList" :fields="fields">-->
    <!--    </b-table>-->
  </div>
</template>

<script>
import gatewayStatus from "@/common/gatewayStatus.json"

export default {
  name: "statusGatewayApi",
  data() {
    return {
      fields: [
        {key: 'code', label: 'CODE'},
        {key: 'description', label: 'Description (EN)',},
        {key: 'descriptionTH', label: 'Description (TH)'},
      ],
      dtacList: gatewayStatus.dtacList,
      trueList: gatewayStatus.trueList,
      drList: gatewayStatus.drList,
    }
  }
}
</script>

<style scoped lang="scss">
.b-table-sticky-header {
  max-height: 200vh;
}
</style>

<style lang="scss">
#developer-page {
  .table th, .table td {
    border: 1px solid #F6F7FB;
  }

  .table-b-table-default {
    position: sticky !important;
    top: -1px;
  }
}
</style>