const Authorization = localStorage.getItem('smsToken')
const ApiURL = 'https://client.sms2pro.com/api/v3'
const createGroupContact = {
    curl: `curl -X POST '` + ApiURL + `/contacts
     -H Authorization: Bearer ` + Authorization + `
     -d 'name' : '{name}'`,
    php: `<?php //Support version greater than or equal 7.X.X 
     $curl = curl_init();
     curl_setopt_array($curl, array(
            CURLOPT_URL => '` + ApiURL + `/contacts', 
            CURLOPT_RETURNTRANSFER => true,
            CURLOPT_ENCODING => '',
            CURLOPT_MAXREDIRS => 10,
            CURLOPT_TIMEOUT => 0,
            CURLOPT_FOLLOWLOCATION => true,
            CURLOPT_HTTP_VERSION => CURL_HTTP_VERSION_1_1,
            CURLOPT_CUSTOMREQUEST => 'POST',
            CURLOPT_HTTPHEADER => array(
            "Content-Type: application/json",
            "Authorization: Bearer ` + Authorization + `"
            ),
            CURLOPT_POSTFIELDS =>json_encode(array(
            'name' : '{name}'
            )),
         ));
     $response = curl_exec($curl);
     curl_close($curl);
     echo $response;`,
    nodejs: `var axios = require('axios');
var config = {
    method: 'post',
    url: '` + ApiURL + `/contacts', 
    headers: {
        "Content-Type: application/json",
        "Authorization: Bearer ` + Authorization + ` "
    },
    data:JSON.stringify({
     'name' : '{name}'
    })
};
axios(config).then(function (response) {
    console.log(JSON.stringify(response.data));
}).catch(function (error) {
    console.log(error);
});`,
    python: `import requests
    url: '` + ApiURL + `/contacts', 
headers = {
    "Accept": "application/json",
    "Content-Type": "application/json",
    "Authorization": "Bearer ` + Authorization + ` "
}
payload = {
 'name' : '{name}'
}
response = requests.request("POST", url, json=payload, headers=headers)
print(response.text)`,
};
const viewGroupContact = {
    curl: `curl -X POST '` + ApiURL + `/contacts/{group_id}/show
     -H Authorization: Bearer ` + Authorization,
    php: `<?php //Support version greater than or equal 7.X.X 
     $curl = curl_init();
     curl_setopt_array($curl, array(
            CURLOPT_URL => '` + ApiURL + `/contacts/{group_id}/show', 
            CURLOPT_RETURNTRANSFER => true,
            CURLOPT_ENCODING => '',
            CURLOPT_MAXREDIRS => 10,
            CURLOPT_TIMEOUT => 0,
            CURLOPT_FOLLOWLOCATION => true,
            CURLOPT_HTTP_VERSION => CURL_HTTP_VERSION_1_1,
            CURLOPT_CUSTOMREQUEST => 'POST',
            CURLOPT_HTTPHEADER => array(
            "Content-Type: application/json",
            "Authorization: Bearer ` + Authorization + `"
            ),
         ));
     $response = curl_exec($curl);
     curl_close($curl);
     echo $response;`,
    nodejs: `var axios = require('axios');
var config = {
    method: 'post',
    url: '` + ApiURL + `/contacts/{group_id}/show', 
    headers: {
        "Content-Type: application/json",
        "Authorization: Bearer ` + Authorization + ` "
    },
};
axios(config).then(function (response) {
    console.log(JSON.stringify(response.data));
}).catch(function (error) {
    console.log(error);
});`,
    python: `import requests
    url: '` + ApiURL + `/contacts/{group_id}/show', 
headers = {
    "Accept": "application/json",
    "Content-Type": "application/json",
    "Authorization": "Bearer ` + Authorization + ` "
}
response = requests.request("POST", url, headers=headers)
print(response.text)`,
};
const updateGroupContact = {
    curl: `curl -X PATCH '` + ApiURL + `/contacts/{group_id}
     -H Authorization: Bearer ` + Authorization + `
     -d 'name' : '{name}'`,
    php: `<?php //Support version greater than or equal 7.X.X 
     $curl = curl_init();
     curl_setopt_array($curl, array(
            CURLOPT_URL => '` + ApiURL + `/contacts/{group_id}', 
            CURLOPT_RETURNTRANSFER => true,
            CURLOPT_ENCODING => '',
            CURLOPT_MAXREDIRS => 10,
            CURLOPT_TIMEOUT => 0,
            CURLOPT_FOLLOWLOCATION => true,
            CURLOPT_HTTP_VERSION => CURL_HTTP_VERSION_1_1,
            CURLOPT_CUSTOMREQUEST => 'PATCH',
            CURLOPT_HTTPHEADER => array(
            "Content-Type: application/json",
            "Authorization: Bearer ` + Authorization + `"
            ),
            CURLOPT_POSTFIELDS =>json_encode(array( 
            'name' : '{name}'
            )),
         ));
     $response = curl_exec($curl);
     curl_close($curl);
     echo $response;`,
    nodejs: `var axios = require('axios');
var config = {
    method: 'PATCH',
    url: '` + ApiURL + `/contacts/{group_id}', 
    headers: {
        "Content-Type: application/json",
        "Authorization: Bearer ` + Authorization + ` "
    },
    data:JSON.stringify({
     'name' : '{name}'
    })
};
axios(config).then(function (response) {
    console.log(JSON.stringify(response.data));
}).catch(function (error) {
    console.log(error);
});`,
    python: `import requests
    url: '` + ApiURL + `/contacts/{group_id}', 
headers = {
    "Accept": "application/json",
    "Content-Type": "application/json",
    "Authorization": "Bearer ` + Authorization + ` "
}
payload = {
 'name' : '{name}'
}
response = requests.request("PATCH", url, json=payload, headers=headers)
print(response.text)`,
};
const deleteGroupContact = {
    curl: `curl -X DELETE '` + ApiURL + `/contacts/{group_id}
     -H Authorization: Bearer ` + Authorization,
    php: `<?php //Support version greater than or equal 7.X.X 
     $curl = curl_init();
     curl_setopt_array($curl, array(
            CURLOPT_URL => '` + ApiURL + `/contacts/{group_id}', 
            CURLOPT_RETURNTRANSFER => true,
            CURLOPT_ENCODING => '',
            CURLOPT_MAXREDIRS => 10,
            CURLOPT_TIMEOUT => 0,
            CURLOPT_FOLLOWLOCATION => true,
            CURLOPT_HTTP_VERSION => CURL_HTTP_VERSION_1_1,
            CURLOPT_CUSTOMREQUEST => 'DELETE',
            CURLOPT_HTTPHEADER => array(
            "Content-Type: application/json",
            "Authorization: Bearer ` + Authorization + `"
            ),
         ));
     $response = curl_exec($curl);
     curl_close($curl);
     echo $response;`,
    nodejs: `var axios = require('axios');
var config = {
    method: 'DELETE',
    url: '` + ApiURL + `/contacts/{group_id}', 
    headers: {
        "Content-Type: application/json",
        "Authorization: Bearer ` + Authorization + ` "
    },
};
axios(config).then(function (response) {
    console.log(JSON.stringify(response.data));
}).catch(function (error) {
    console.log(error);
});`,
    python: `import requests
    url: '` + ApiURL + `/contacts/{group_id}', 
headers = {
    "Accept": "application/json",
    "Content-Type": "application/json",
    "Authorization": "Bearer ` + Authorization + ` "
}
response = requests.request("DELETE", url, headers=headers)
print(response.text)`,
};
const viewAllGroupContact = {
    curl: `curl -X GET '` + ApiURL + `/contacts
     -H Authorization: Bearer ` + Authorization,
    php: `<?php //Support version greater than or equal 7.X.X 
     $curl = curl_init();
     curl_setopt_array($curl, array(
            CURLOPT_URL => '` + ApiURL + `/contacts', 
            CURLOPT_RETURNTRANSFER => true,
            CURLOPT_ENCODING => '',
            CURLOPT_MAXREDIRS => 10,
            CURLOPT_TIMEOUT => 0,
            CURLOPT_FOLLOWLOCATION => true,
            CURLOPT_HTTP_VERSION => CURL_HTTP_VERSION_1_1,
            CURLOPT_CUSTOMREQUEST => 'GET',
            CURLOPT_HTTPHEADER => array(
            "Content-Type: application/json",
            "Authorization: Bearer ` + Authorization + `"
            ),
         ));
     $response = curl_exec($curl);
     curl_close($curl);
     echo $response;`,
    nodejs: `var axios = require('axios');
var config = {
    method: 'GET',
    url: '` + ApiURL + `/contacts', 
    headers: {
        "Content-Type: application/json",
        "Authorization: Bearer ` + Authorization + ` "
    },
};
axios(config).then(function (response) {
    console.log(JSON.stringify(response.data));
}).catch(function (error) {
    console.log(error);
});`,
    python: `import requests
    url: '` + ApiURL + `/contacts', 
headers = {
    "Accept": "application/json",
    "Content-Type": "application/json",
    "Authorization": "Bearer ` + Authorization + ` "
}
response = requests.request("GET", url, headers=headers)
print(response.text)`,
};
export default {
    createGroupContact,
    viewGroupContact,
    updateGroupContact,
    deleteGroupContact,
    viewAllGroupContact
};
