<template>
  <div>
    <detail-template
        type-key="header"
        :topic="contact.topic"
        :detail="contact.detail"
        :end-point="contact.apiEndpoint"
        :parameter-items="contact.parameterItems"
    />
    <detail-template
        :topic="create.topic"
        :detail="create.detail"
        :end-point="create.apiEndpoint"
        :parameter-items="create.parameterItems"
    />
    <CodeTemplate
        @click="createContact"
        :data="createData"
        :template-config="codeTemplateConfig.createContact"
        :result="result"
    />
    <detail-template
        :topic="view.topic"
        :detail="view.detail"
        :end-point="view.apiEndpoint"
        :parameter-items="view.parameterItems"
    />
    <CodeTemplate
        @click="viewContact"
        :data="viewData"
        :template-config="codeTemplateConfig.viewContact"
        :result="resultView"
    />
    <detail-template
        :topic="update.topic"
        :detail="update.detail"
        :end-point="update.apiEndpoint"
        :parameter-items="update.parameterItems"
    />
    <CodeTemplate
        @click="updateContact"
        :data="updateData"
        :template-config="codeTemplateConfig.updateContact"
        :result="resultUpdate"
    />
    <detail-template
        :topic="deletePram.topic"
        :detail="deletePram.detail"
        :end-point="deletePram.apiEndpoint"
        :parameter-items="deletePram.parameterItems"
    />
    <CodeTemplate
        @click="deleteContact"
        :data="deleteData"
        :template-config="codeTemplateConfig.deleteContact"
        :result="resultDelete"
    />
    <detail-template
        :topic="viewAll.topic"
        :detail="viewAll.detail"
        :end-point="viewAll.apiEndpoint"
    />
    <CodeTemplate
        @click="viewAllContact"
        :data="viewAllData"
        :template-config="codeTemplateConfig.viewAllContact"
        :result="resultViewAll"
    />
  </div>
</template>

<script>
import CodeTemplate from "../commonAPI/codeTemplate";
import codeTemplateConfig from "./codeTemplateConfig";
import DetailTemplate from "@/views/smsAPI/commonAPI/detailTemplate";
import contactApi from "@/repository/apiContact";

export default {
  name: "contactApi",
  components: {
    DetailTemplate,
    CodeTemplate,
  },
  data() {
    return {
      codeTemplateConfig,
      contact: {
        topic: 'Contacts API',
        detail: 'SMS2PRO Contacts API helps you manage contacts that are identified by a unique random ID. Using this ID, you can create, view, update, or delete contacts. This API works as a collection of customer-specific contacts that allows you to group them and assign custom values that you can later use when sending SMS template messages.\n' +
            '\n' +
            'The Contacts API uses HTTP verbs and a RESTful endpoint structure with an access key that is used as the API Authorization. Request and response payloads are formatted as JSON using UTF-8 encoding and URL encoded values.',
        apiEndpoint: ' https://client.sms2pro.com/api/v3/contacts',
        parameterItems: [
          {
            parameter: "Authorization",
            required: {statusbg: "success", statustype: "Yes"},
            description: "When calling our API, send your api token with the authentication type set as <span style=\"color:#e74c3c\"><code><span style=\"background-color:#dddddd\">Bearer</span></code></span> (Example: <span style=\"color:#e74c3c\"><code><span style=\"background-color:#dddddd\">Authorization: Bearer {api_token}</span></code></span>)",
          },
          {
            parameter: "Accept",
            required: {statusbg: "success", statustype: "Yes"},
            description: "Set to <span style=\"color:#e74c3c\"><code><span style=\"background-color:#dddddd\">application/json</span></code></span>",
          },
        ],
      },
      create: {
        topic: 'Create a contact',
        detail: 'Creates a new contact object. SMS2PRO returns the created group object with each request.',
        apiEndpoint: 'https://client.sms2pro.com/api/v3/contacts/<span style="color:#e74c3c">{group_id}</span>/store',
        parameterItems: [
          {
            parameter: "group_id",
            required: {statusbg: "success", statustype: "Yes"},
            type: "string",
            description: "Contact Groups <span style=\"color:#e74c3c\"><code><span style=\"background-color:#dddddd\">uid</span></code></span>",
          },
          {
            parameter: "phone",
            required: {statusbg: "success", statustype: "Yes"},
            type: "number",
            description: "The phone number of the _contact.",
          },
          {
            parameter: "first_name",
            required: {statusbg: "", statustype: "No"},
            type: "string",
            description: "The first name of the _contact.",
          },
          {
            parameter: "last_name",
            required: {statusbg: "", statustype: "No"},
            type: "string",
            description: "The last name of the _contact.",
          },
        ],
      },
      result: {},
      view: {
        topic: 'View a contact',
        detail: 'Retrieves the information of an existing contact. You only need to supply the unique contact uid and group uid that was returned upon creation or receiving.',
        apiEndpoint: 'https://client.sms2pro.com/api/v3/contacts/<span style="color:#e74c3c">{group_id}</span>/search/<span style="color:#e74c3c">{uid}</span>',
        parameterItems: [
          {
            parameter: "group_id",
            required: {statusbg: "success", statustype: "Yes"},
            type: "string",
            description: "Contact Groups <span style=\"color:#e83e8c;background-color:#dddddd\">uid</span>",
          },
          {
            parameter: "uid",
            required: {statusbg: "success", statustype: "Yes"},
            type: "string",
            description: "Contact <span style=\"color:#e83e8c;background-color:#dddddd\">uid</span>",
          },
        ],

      },
      resultView: {},
      update: {
        topic: 'Update a contact',
        detail: 'Updates an existing _contact. You only need to supply the unique uid of _contact and _contact group uid that was returned upon creation.',
        apiEndpoint: ' https://client.sms2pro.com/api/v3/contacts/<span style="color:#e74c3c">{group_id}</span>/update/<span style="color:#e74c3c">{uid}</span>',
        parameterItems: [
          {
            parameter: "group_id",
            required: {statusbg: "success", statustype: "Yes"},
            type: "string",
            description: "Contact Groups <span style=\"color:#e74c3c\"><code><span style=\"background-color:#dddddd\">uid</span></code></span>",
          },
          {
            parameter: "uid",
            required: {statusbg: "success", statustype: "Yes"},
            type: "string",
            description: "Contact <span style=\"color:#e74c3c\"><code><span style=\"background-color:#dddddd\">uid</span></code></span>",
          },
          {
            parameter: "phone",
            required: {statusbg: "success", statustype: "Yes"},
            type: "number",
            description: "The phone number of the _contact.",
          },
          {
            parameter: "first_name",
            required: {statusbg: "", statustype: "No"},
            type: "string",
            description: "The first name of the _contact.",
          },
          {
            parameter: "last_name",
            required: {statusbg: "", statustype: "No"},
            type: "string",
            description: "The last name of the _contact.",
          },
        ],
      },
      resultUpdate: {},
      deletePram: {
        topic: 'Delete a contact',
        detail: 'Deletes an existing _contact. You only need to supply the unique _contact uid and group uid that was returned upon creation.',
        apiEndpoint: ' https://client.sms2pro.com/api/v3/contacts/<span style="color:#e74c3c">{group_id}</span>/delete/<span style="color:#e74c3c">{uid}</span>',
        parameterItems: [
          {
            parameter: "group_id",
            required: {statusbg: "success", statustype: "Yes"},
            type: "string",
            description: "Contact Groups <span style=\"color:#e74c3c\"><code><span style=\"background-color:#dddddd\">uid</span></code></span>",
          },
          {
            parameter: "uid",
            required: {statusbg: "success", statustype: "Yes"},
            type: "string",
            description: "Contact <span style=\"color:#e74c3c\"><code><span style=\"background-color:#dddddd\">uid</span></code></span>",
          },
        ],
      },
      resultDelete: {},
      viewAll: {
        topic: 'View all contacts in group',
        detail: '',
        apiEndpoint: ' https://client.sms2pro.com/api/v3/contacts/<span style="color:#e74c3c">{group_id}</span>/all',
        parameterItems: [
          {
            parameter: "group_id",
            required: {statusbg: "success", statustype: "Yes"},
            type: "string",
            description: "Contact Groups <span style=\"color:#e74c3c\"><code><span style=\"background-color:#dddddd\">uid</span></code></span>",
          },
        ],
      },
      resultViewAll: {},
      createData: {
        fields: [
          {
            label: "group_id",
            field: "group_id",
            placeholder: "Input group id",
            // required:"required"
          },
          {
            label: "phone",
            field: "phone",
            placeholder: "Input phone",
            // required:"required"
          },
          {
            label: "first_name",
            field: "first_name",
            placeholder: "Input first name",
          },
          {
            label: "last_name",
            field: "last_name",
            placeholder: "Input last name",
          }
        ],
      },
      viewData: {
        fields: [
          {
            label: "group_id",
            field: "group_id",
            placeholder: "Input group_id",
          },
          {
            label: "uid",
            field: "uid",
            placeholder: "Input uid",
          }
        ],
      },
      updateData: {
        fields: [
          {
            label: "group_id",
            field: "group_id",
            placeholder: "Input group_id",
            // required:"required"
          },
          {
            label: "uid",
            field: "uid",
            placeholder: "Input uid",
            // required:"required"
          },
          {
            label: "phone",
            field: "phone",
            placeholder: "Input phone",
            // required:"required"
          },
          {
            label: "first_name",
            field: "first_name",
            placeholder: "Input first name",
          },
          {
            label: "last_name",
            field: "last_name",
            placeholder: "Input last name",
          }
        ],
      },
      deleteData: {
        fields: [
          {
            label: "group_id",
            field: "group_id",
            placeholder: "Input group_id",
            // required:"required"
          },
          {
            label: "uid",
            field: "uid",
            placeholder: "Input group_id",
            // required:"required"
          }
        ],
      },
      viewAllData: {
        fields: [
          {
            label: "group_id",
            field: "group_id",
            placeholder: "Input group_id",
            // required:"required"
          }
        ],
      },
    };
  },
  methods: {
    createContact(param) {
      contactApi.createContact(param).then(response => {
        this.result = response
      }).catch(err => {
        this.result = err
      })
    },
    viewContact(param) {
      contactApi.viewContact(param).then(response => {
        this.resultView = response
      }).catch(err => {
        this.resultView = err
      })
    },
    updateContact(param) {
      contactApi.updateContact(param).then(response => {
        this.resultUpdate = response
      }).catch(err => {
        this.resultUpdate = err
      })
    },
    deleteContact(param) {
      contactApi.deleteContact(param).then(response => {
        this.resultDelete = response
      }).catch(err => {
        this.resultDelete = err
      })
    },
    viewAllContact(param) {
      contactApi.viewAllContact(param).then(response => {
        this.resultViewAll = response
      }).catch(err => {
        this.resultViewAll = err
      })
    }
  }
};
</script>

