<template>
  <div>
    <b-row class="mb-3">
      <b-col sm="12" lg="3" md="3">
        <topic-api @click="activeDoc"/>
      </b-col>
      <b-col sm="12" lg="9" md="9">
        <b-card class="mb-3">
          <b-row align-h="end" class="mr-1">
            <b-button :disabled="!checkPermission('refresh-token', 'action', 'api-documents')" variant="primary"
                      @click="updateContact"><i class="fas fa-retweet"></i> Update API Key
            </b-button>
          </b-row>
          <div class="box-endpoint" v-if="checkPermission('view-token', 'action', 'api-documents')">
            <b-row class="align-items-center pt-3">
              <b-col sm="3" class="text-left text-md-right">
                <label class="fw-medium mb-0 label-for-topic-api"> API Endpoint : </label>
              </b-col>
              <b-col sm="9">
                <label class="fw-medium mb-0">{{ endpoint }}</label>
              </b-col>
            </b-row>
            <b-row class="py-3 align-items-center">
              <b-col sm="3" class="text-left text-md-right">
                <label class="fw-medium mb-0 label-for-topic-api"> API Key : </label>
              </b-col>
              <b-col sm="9">
                <label class="fw-medium mb-0 p-1 wrap-text" :title="apiToken">
                  {{ apiToken === '-' ? apiToken : strToken(apiToken) }}
                </label>
                <b-button variant="gradient" class="ml-2" rounded="lg" size="sm"
                          v-clipboard="tokenAPI"
                          v-clipboard:success="clipboardSuccessHandler"
                          v-clipboard:error="clipboardErrorHandler"
                >
                  <i class="far fa-clipboard "></i>
                </b-button>
              </b-col>
            </b-row>
          </div>
        </b-card>
        <b-card>
          <!--          <contact-group  v-if="selectedDoc==='Contact Group API'"/>-->
          <!--          <contact-api v-if="selectedDoc==='Contact API'"/>-->
          <mkt-api @output="getCredit" v-if="selectedDoc === 'Marketing API'"/>
          <sms-api @output="getCredit" v-if="selectedDoc === 'SMS API'"/>
          <otp-api @output="getCredit" v-if="selectedDoc === 'SMS OTP'"/>
          <profile-api v-if="selectedDoc === 'Profile API'"/>
          <status-gateway-api v-show="selectedDoc === 'Status Gateway'"/>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import TopicApi from "@/views/smsAPI/topic";
import StatusGatewayApi from "@/views/smsAPI/statusGateway";
import ContactGroup from "@/views/smsAPI/contactGroup";
import ContactApi from "@/views/smsAPI/contact";
import SmsApi from "@/views/smsAPI/sms";
import OtpApi from "@/views/smsAPI/otp";
import ProfileApi from "@/views/smsAPI/profile";
import MktApi from "@/views/smsAPI/mkt";
import alert from "@/common/alert";
import profileApi from "@/repository/profileApi";
import functions from "@/common/functions";
import authApi from "@/repository/authApi";
import {store} from "@/store";

export default {
  name: "index",
  components: {MktApi, ProfileApi, OtpApi, SmsApi, ContactApi, ContactGroup, StatusGatewayApi, TopicApi},
  data() {
    return {
      selectedDoc: 'Status Gateway',
      endpoint: window.location.origin + '/sms-api',
      tokenAPI: store.getters['profile/getSMSToken']
    }
  },
  computed: {
    userInfo() {
      return JSON.parse(localStorage.getItem('profile'))
    },
    apiToken() {
      return this.$store.getters["profile/getSMSToken"];
    },
  },
  created() {
    this.getApiToken()
  },
  methods: {
    activeDoc(e) {
      this.selectedDoc = e
    },
    checkCurrentRoute() { // update current popup credit
      const prefix = this.selectedDoc
      if (prefix === 'Marketing API' || prefix === 'SMS API' || prefix === 'SMS OTP') {
        this.$store.dispatch('profile/setSMSRoute', true)
      } else {
        this.$store.dispatch('profile/setSMSRoute', false)
      }
    },
    getCredit() {
      authApi.getCustomerProfile(true).then(() => {
        this.checkCurrentRoute()
      }).catch((err) => {
        throw err
      })
    },
    getApiToken() {
      profileApi.getApiToken().then(response => {
        if (response?.codeSYS === '001') {
          localStorage.setItem('smsToken', response?.api_token)
          this.$store.dispatch('profile/setSMSToken', response?.api_token)
        }
      })
    },
    clipboardSuccessHandler({value, event}) {
      this.$bvToast.toast(`Copy Successful`, {
        title: 'Notice!',
        variant: 'success',
        autoHideDelay: 2000
      })
    },
    updateContact() {
      profileApi.generateApiToken().then(response => {
        if (response?.codeSYS === '001') {
          this.$router.push('/Loading-Developer').catch(() => {
          })
        } else {
          alert.updateFailed(this, response.message)
        }
      }).catch(err => {
        alert.updateFailed(this, err.response?.data?.message)
      }).finally(() => {
        this.getApiToken()
      })
    },
    clipboardErrorHandler({value, event}) {
      this.$bvToast.toast(`Copy Failed`, {
        title: 'Notice!',
        variant: 'danger',
        autoHideDelay: 2000
      })
    },
    checkPermission(permission, type, modules) {
      return functions.userCheckPermission(permission, type, modules)
    },
    strToken(token) {
      let str = token ? token?.substring(0, 70) : '-'
      return str.length <= 100 && str.length > 1 ? str + '...' : str
    }
  }
}
</script>

<style>
.label-for-topic-api {
  /*font-family: 'Eudoxus Sans';*/
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  color: var(--secondary-color);
  padding-bottom: 0px
}

.box-endpoint {
  background: #F6F7FB;
  border-radius: 5px;
  margin-top: 10px
}

.wrap-text {
  word-break: break-all !important;
}
</style>