<template>
  <div>
    <h4 class="card-title">{{topic}}</h4>
    <div class="mb-2">
      <label v-show="detail"> {{ detail }}</label>
      <div v-show="endPoint">
        <span class="label-for-topic-api">API Endpoint</span>
      </div>
      <div class="code-blog" v-show="endPoint">
        <span v-html="api_endpoint+endPoint"></span>
      </div>
      <span class="label-for-topic-api" v-show="typeKey === 'header'">Headers</span>
      <span class="label-for-topic-api" v-show="parameterItems.length>0 && !typeKey">Parameters</span>
      <b-table responsive class="mb-0" :items="parameterItems">
        <template #cell(required)="data">
          <b-badge
              pill
              :class="`px-2 text-white badge bg-${data.item.required.statusbg}`"
          >
            <i class="font-9 mdi mdi-checkbox-blank-circle"></i>
            {{ data.item.required.statustype }}
          </b-badge>
        </template>
        <template #cell(description)="data">
          <label v-html="data.item.description"/>
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
export default {
  name: "detailTemplate",
  props: {
    parameterItems: {
      type: Array, // title, fields: { label, field }
      default: Array,
    },
    topic: {
      type: String,
      default: "",
    },
    detail: {
      type: String,
      default: "",
    },
    endPoint: {
      type: String,
      default: "",
    },
    typeKey: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      api_endpoint: window.location.origin + '/sms-api'
    }
  }
}
</script>

<style scoped>
.code-blog {
  background-color: #011627;
  border-radius: 5px;
  padding: 5px;
  color: rgba(224, 223, 223, 0.99);
  margin-bottom: 10px;
  margin-top: 5px
}

label {
  font-family: "Prompt";
}
</style>