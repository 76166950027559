<template>
  <div>
    <span class="label-for-topic-api ">Example request</span>
    <b-row class="mt-2">
      <b-col lg="4" sm="12" class="mb-2">
        <Input
            v-for="item in data.fields"
            :key="item.id"
            @modelValue="(val) => form[item.field] = val"
            @preventCustomOTPPattern="preventCustomOTPPattern"
            :label="item.label"
            :id="item.id"
            :required="item.required"
            :placeholder="item.placeholder"
            :input-type="item.field"
            class="mb-2"
        />
        <b-row class="mb-2 mt-3" style="padding-left: 10px" v-if="lastHint">
          <b-col lg="12" sm="12" md="12" style="padding-left: 10px">
            <p :style="lastHint.topic.style">{{ lastHint.topic.title }}</p>
          </b-col>
          <b-col lg="12" sm="12" md="12" style="padding-left: 10px">
            <span>{{ lastHint.massage.massage_1 }}</span>
            <span :style="lastHint.massage.style" class="text-bold">{{ lastHint.massage.otp }}</span>
            <span>{{ lastHint.massage.massage_2 }}</span>
            <span :style="lastHint.massage.style" class="text-bold">{{ lastHint.massage.validity }}</span>
            <span>{{ lastHint.massage.massage_3 }}</span>
            <span :style="lastHint.massage.style" class="text-bold">{{ lastHint.massage.refCode }}</span>
          </b-col>
        </b-row>
        <b-row align-h="end" class="mr-1 mt-3">
          <b-button variant="primary" @click="$emit('click',form)" :disabled="!objectLength">
            <i class="fas fa-code"></i> Try it
          </b-button>
        </b-row>
      </b-col>
      <b-col lg="8" sm="12" class="mb-2">
        <Tab :options="tabOptions" @active="(val) => tabActive = val"/>
        <prism :code="previewCode"></prism>
        <b-tabs content-class="mt-3">
          <b-tab title="Response" active>
            <pre style="background-color: #f5f2f0" class="p-3">{{ result }}</pre>
          </b-tab>
        </b-tabs>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import Prism from 'vue-prismjs'
import 'prismjs/themes/prism.css'
import Input from "./input";
import Tab from "./tab";
import tr from "vue2-datepicker/locale/es/tr";

export default {
  name: "CodeTemplate",
  components: {
    Input,
    Tab,
    Prism
  },
  props: {
    lastHint: {
      type: [Object, Boolean],
      default: false
    },
    category: {
      type: String,
      default: String
    },
    data: {
      type: Object, // title, fields: { label, field }
      default: Object,
    },
    templateConfig: {
      type: Object,
      default: "",
    },
    result: {
      type: Object,
      default: Object,
    }
  },
  data() {
    return {
      isEvery: true,
      tabActive: "curl",
      tabOptions: [
        {id: "curl", label: "CURL"},
        {id: "php", label: "PHP cURL"},
        {id: "nodejs", label: "NodeJS (Axios)"},
        {id: "python", label: "Python"},
      ],
      form: this.data.fields.reduce(
          (prev, next) => ({...prev, [next.field]: ""}),
          {}
      ),
    };
  },
  computed: {
    hasLastHintCustomMassage() {
      if (this.lastHint) {
        return this.lastHint.massage.massage_1
      }
      return null
    },
    objectLength() {
      if (this.category === 'mkt_createSMS') {
        return this.form['name'].length > 0 && this.form['recipients'].length > 0 && this.form['sender_name'].length > 0 && this.form['message'].length > 0
      } else if (this.category === 'sms_createSMS') {
        return this.form['recipient'].length > 0 && this.form['sender_name'].length > 0 && this.form['message'].length > 0
      } else if (this.category === 'otp_createSMS') {
        return this.form['recipient'].length > 0 && this.form['sender_name'].length > 0 && this.isEvery
      } else if (this.category === 'otp_viewSMS') {
        return this.form['token'].length > 0 && this.form['otp_code'].length > 0
      } else if (this.category === 'mkt_viewSMS' || this.category === 'sms_viewSMS') {
        return this.form['uid'].length > 0
      } else {
        return true
      }
    },
    previewCode() {
      let code = this.templateConfig[this.tabActive];
      for (const key in this.form) {
        if (this.form[key]) code = code.replaceAll(`{${key}}`, this.form[key]);
      }
      return code;
    },
  },
  methods: {
    preventCustomOTPPattern(customOTP) {
      customOTP.e.length > 0 ? this.isEvery = customOTP.isEvery : this.isEvery = true
    }
  }
};
</script>

<style scoped>
/* .tab-active {
  bac
} */
</style>