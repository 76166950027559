<template>
  <div>
    <detail-template
        type-key="header"
        :topic="profile.topic"
        :detail="profile.detail"
        :end-point="profile.apiEndpoint"
        :parameter-items="profile.parameterItems"
    />
    <detail-template
        :topic="viewSMSUnit.topic"
        :detail="viewSMSUnit.detail"
        :end-point="viewSMSUnit.apiEndpoint"
        :parameter-items="viewSMSUnit.parameterItems"
    />
    <CodeTemplate
        @click="getSMSUnit"
        :data="viewSMSUnitData"
        :template-config="codeTemplateConfig.viewSMSUnit"
        :result="resultViewSMSUnit"
    />
    <detail-template
        :topic="viewProfile.topic"
        :detail="viewProfile.detail"
        :end-point="viewProfile.apiEndpoint"
        :parameter-items="viewProfile.parameterItems"
    />
    <CodeTemplate
        @click="getProfile"
        :data="viewProfileData"
        :template-config="codeTemplateConfig.viewProfile"
        :result="resultViewProfile"
    />
  </div>
</template>

<script>
import CodeTemplate from "../commonAPI/codeTemplate";
import codeTemplateConfig from "./codeTemplateConfig";
import DetailTemplate from "@/views/smsAPI/commonAPI/detailTemplate";
import smsApi from "@/repository/apiProfile";

export default {
  name: "profileApi",
  components: {
    DetailTemplate,
    CodeTemplate,
  },
  data() {
    return {
      codeTemplateConfig,
      profile: {
        topic: 'Profile API',
        detail: 'SMS Profile API allows you to retrieve your total remaining sms unit, used sms unit, and your profile information.',
        apiEndpoint: '/profile',
        parameterItems: [
          {
            parameter: "Authorization",
            required: {statusbg: "success", statustype: "Yes"},
            description: "When calling our API, send your api token with the authentication type set as <span style=\"color:#e74c3c\"><code><span style=\"background-color:#dddddd\">Bearer</span></code></span> (Example: <span style=\"color:#e74c3c\"><code><span style=\"background-color:#dddddd\">Authorization: Bearer {api_token}</span></code></span>)",
          },
          {
            parameter: "Accept",
            required: {statusbg: "success", statustype: "Yes"},
            description: "Set to <span style=\"color:#e74c3c\"><code><span style=\"background-color:#dddddd\">application/json</span></code></span>",
          },
        ],
      },
      viewSMSUnit: {
        topic: 'View sms unit',
        detail: '',
        apiEndpoint: '/profile/get-balance',
        parameterItems: [],
      },
      resultViewSMSUnit: {},
      viewProfile: {
        topic: 'View Profile',
        detail: '',
        apiEndpoint: '/profile/get',
        parameterItems: [],

      },
      resultViewProfile: {},
      viewSMSUnitData: {
        fields: [],
      },
      viewProfileData: {
        fields: [],
      },
    };
  },
  methods: {
    getProfile() {
      smsApi.viewProfile().then(response => {
        this.resultViewProfile = response
      }).catch(err => {
        this.resultViewProfile = err
      })
    },
    getSMSUnit() {
      smsApi.viewSMSUnit().then(response => {
        this.resultViewSMSUnit = response
      }).catch(err => {
        this.resultViewSMSUnit = err
      })
    },
  }
};
</script>

