import service from './sms2ProAxios'

const resourcePath = '/campaign-sms'
export default {
    viewAllSMS(data) {
        return service.apiSms2pro.get(`${resourcePath}/list`)
            .then(resp => {
                return resp.data
            }).catch(err => {
                throw err.response.data
            })
    },
    viewSMS(data) {
        return service.apiSms2pro.get(`${resourcePath}/get/${data?.uid}`)
            .then(resp => {
                return resp.data
            }).catch(err => {
                throw err.response.data
            })
    },
    sendSMS(data) {
        const mapBody = {
            name: data?.name,
            recipients: data?.recipients,
            sender_name: data?.sender_name,
            message: data?.message,
            send_date: data?.send_date,
        }
        return service.apiSms2pro.post(`${resourcePath}/send`, mapBody)
            .then(resp => {
                return resp.data
            }).catch(err => {
                throw err.response.data
            })
    },

    sendOTP(data) {
        const mapBody = {
            ref_code: data?.ref_code,
            token: data?.token,
            otp_code: data?.otp_code,
        }
        return service.apiSms2pro.post(`${resourcePath}/otp-verify`, mapBody)
            .then(resp => {
                return resp.data
            }).catch(err => {
                throw err.response.data
            })
    },
}