import service from './sms2ProAxios'

const resourcePath = '/message-sms'
export default {
    viewAllSMS(data) {
        return service.apiSms2pro.get(`${resourcePath}/list`)
            .then(resp => {
                return resp.data
            }).catch(err => {
                throw err.response.data
            })
    },
    viewSMS(data) {
        return service.apiSms2pro.get(`${resourcePath}/get/${data?.uid}`)
            .then(resp => {
                return resp.data
            }).catch(err => {
                throw err.response.data
            })
    },
    sendSMS(data) {
        const mapBody = {
            recipient: data?.recipient,
            sender_name: data?.sender_name,
            message: data?.message,
        }
        return service.apiSms2pro.post(`${resourcePath}/send`, mapBody)
            .then(resp => {
                return resp.data
            }).catch(err => {
                throw err.response.data
            })
    },

    sendOTP(data) {
        const mapBody = {
            recipient: data?.recipient,
            sender_name: data?.sender_name,
            ref_code: data?.ref_code,
            digit: data?.digit,
            validity: data?.validity,
            custom_message: data?.custom_message
        }
        return service.apiSms2pro.post(`/otp-sms/send`, mapBody)
            .then(resp => {
                return resp.data
            }).catch(err => {
                throw err.response.data
            })
    },
    SMSVerify(data) {
        const mapBody = {
            ref_code: data?.ref_code,
            token: data?.token,
            otp_code: data?.otp_code,
        }
        return service.apiSms2pro.post(`/otp-sms/verify`, mapBody)
            .then(resp => {
                return resp.data
            }).catch(err => {
                throw err.response.data
            })
    },
}