<template>
  <div>
    <detail-template
        type-key="header"
        :topic="contact.topic"
        :detail="contact.detail"
        :end-point="contact.apiEndpoint"
        :parameter-items="contact.parameterItems"
    />
    <detail-template
        :topic="createGroupContact.topic"
        :detail="createGroupContact.detail"
        :end-point="createGroupContact.apiEndpoint"
        :parameter-items="createGroupContact.parameterItems"
    />
    <CodeTemplate
        @click="createContactGroup"
        :data="createGroupData"
        :template-config="codeTemplateConfig.createGroupContact"
        :result="resultGroupContact"
    />
    <detail-template
        :topic="viewGroupContact.topic"
        :detail="viewGroupContact.detail"
        :end-point="viewGroupContact.apiEndpoint"
        :parameter-items="viewGroupContact.parameterItems"
    />
    <CodeTemplate
        @click="viewContactGroup"
        :data="viewGroupData"
        :template-config="codeTemplateConfig.viewGroupContact"
        :result="resultViewGroupContact"
    />
    <detail-template
        :topic="updateGroupContact.topic"
        :detail="updateGroupContact.detail"
        :end-point="updateGroupContact.apiEndpoint"
        :parameter-items="updateGroupContact.parameterItems"
    />
    <CodeTemplate
        @click="updateContactGroup"
        :data="updateGroupData"
        :template-config="codeTemplateConfig.updateGroupContact"
        :result="resultUpdateGroupContact"
    />
    <detail-template
        :topic="deleteGroupContact.topic"
        :detail="deleteGroupContact.detail"
        :end-point="deleteGroupContact.apiEndpoint"
        :parameter-items="deleteGroupContact.parameterItems"
    />
    <CodeTemplate
        @click="deleteContactGroup"
        :data="deleteGroupData"
        :template-config="codeTemplateConfig.deleteGroupContact"
        :result="resultDeleteGroupContact"
    />
    <detail-template
        :topic="viewAllGroupContact.topic"
        :detail="viewAllGroupContact.detail"
        :end-point="viewAllGroupContact.apiEndpoint"
    />
    <CodeTemplate
        @click="viewAllContactGroup"
        :data="viewAllGroupData"
        :template-config="codeTemplateConfig.viewAllGroupContact"
        :result="resultViewAllGroupContact"
    />
  </div>
</template>

<script>
import CodeTemplate from "../commonAPI/codeTemplate";
import codeTemplateConfig from "./codeTemplateConfig";
import DetailTemplate from "@/views/smsAPI/commonAPI/detailTemplate";
import contactGroupApi from "@/repository/apiContactGroup";

export default {
  name: "contactGroup",
  components: {
    DetailTemplate,
    CodeTemplate,
  },
  data() {
    return {
      codeTemplateConfig,
      contact: {
        topic: 'Contact Groups API',
        detail: 'SMS2PRO Contact Groups API allows you to manage _contact groups that are identified by a unique random ID. Use this ID to create, view, update or delete groups.',
        apiEndpoint: ' https://client.sms2pro.com/api/v3/contacts',
        parameterItems: [
          {
            parameter: "Authorization",
            required: {statusbg: "success", statustype: "Yes"},
            description: "When calling our API, send your api token with the authentication type set as <span style=\"color:#e74c3c\"><code><span style=\"background-color:#dddddd\">Bearer</span></code></span> (Example: <span style=\"color:#e74c3c\"><code><span style=\"background-color:#dddddd\">Authorization: Bearer {api_token}</span></code></span>)",
          },
          {
            parameter: "Accept",
            required: {statusbg: "success", statustype: "Yes"},
            description: "Set to <span style=\"color:#e74c3c\"><code><span style=\"background-color:#dddddd\">application/json</span></code></span>",
          },
        ],
      },
      createGroupContact: {
        topic: 'Create a group',
        detail: 'Creates a new group object. SMS2PRO returns the created group object with each request.',
        apiEndpoint: ' https://client.sms2pro.com/api/v3/contacts',
        parameterItems: [
          {
            parameter: "name",
            required: {statusbg: "success", statustype: "Yes"},
            type: "string",
            description: "The name of the group",
          },
        ],

      },
      resultGroupContact: {},
      viewGroupContact: {
        topic: 'View a group',
        detail: 'Retrieves the information of an existing group. You only need to supply the unique group ID that was returned upon creation or receiving.',
        apiEndpoint: 'https://client.sms2pro.com/api/v3/contacts/<span style="color:#e74c3c">{group_id}</span>/show',
        parameterItems: [
          {
            parameter: "group_id",
            required: {statusbg: "success", statustype: "Yes"},
            type: "string",
            description: "Contact Groups <span style=\"color:#e83e8c;background-color:#dddddd\">uid</span>",
          },
        ],

      },
      resultViewGroupContact: {},
      updateGroupContact: {
        topic: 'Update a group',
        detail: 'Updates an existing group. You only need to supply the unique ID that was returned upon creation.',
        apiEndpoint: 'https://client.sms2pro.com/api/v3/contacts/<span style="color:#e74c3c">{group_id}</span>',
        parameterItems: [
          {
            parameter: "group_id",
            required: {statusbg: "success", statustype: "Yes"},
            type: "string",
            description: "Contact Groups <span style=\"color:#e83e8c;background-color:#dddddd\">uid</span>",
          },
          {
            parameter: "name",
            required: {statusbg: "success", statustype: "Yes"},
            type: "string",
            description: "New group name",
          },
        ],

      },
      resultUpdateGroupContact: {},
      deleteGroupContact: {
        topic: 'Delete a group',
        detail: 'Deletes an existing group. You only need to supply the unique id that was returned upon creation.',
        apiEndpoint: 'https://client.sms2pro.com/api/v3/contacts/<span style="color:#e74c3c">{group_id}</span>',
        parameterItems: [
          {
            parameter: "group_id",
            required: {statusbg: "success", statustype: "Yes"},
            type: "string",
            description: "Contact Groups <span style=\"color:#e83e8c;background-color:#dddddd\">uid</span>",
          },
        ],

      },
      resultDeleteGroupContact: {},
      viewAllGroupContact: {
        topic: 'View all groups',
        detail: '',
        apiEndpoint: 'https://client.sms2pro.com/api/v3/contacts',
        parameterItems: [],
      },
      resultViewAllGroupContact: {},
      createGroupData: {
        fields: [
          {
            label: "name",
            field: "name",
            placeholder: "Input name",
            // required:"required"
          }
        ],
      },
      viewGroupData: {
        fields: [
          {
            label: "group_id",
            field: "group_id",
            placeholder: "Input group_id",
            // required:"required"
          }
        ],
      },
      updateGroupData: {
        fields: [
          {
            label: "group_id",
            field: "group_id",
            placeholder: "Input group_id",
            // required:"required"
          },
          {
            label: "name",
            field: "name",
            placeholder: "Input name",
            // required:"required"
          },
        ],
      },
      deleteGroupData: {
        fields: [
          {
            label: "group_id",
            field: "group_id",
            placeholder: "Input group_id",
            // required:"required"
          }
        ],
      },
      viewAllGroupData: {
        fields: [],
      },
    };
  },
  methods: {
    createContactGroup(param) {
      contactGroupApi.createContactGroup(param).then(response => {
        this.resultGroupContact = response
      }).catch(err => {
        this.resultGroupContact = err
      })
    },
    viewContactGroup(param) {
      contactGroupApi.viewContactGroup(param).then(response => {
        this.resultViewGroupContact = response
      }).catch(err => {
        this.resultViewGroupContact = err
      })
    },
    updateContactGroup(param) {
      contactGroupApi.updateContactGroup(param).then(response => {
        this.resultUpdateGroupContact = response
      }).catch(err => {
        this.resultUpdateGroupContact = err
      })
    },
    deleteContactGroup(param) {
      contactGroupApi.deleteContactGroup(param).then(response => {
        this.resultDeleteGroupContact = response
      }).catch(err => {
        this.resultDeleteGroupContact = err
      })
    },
    viewAllContactGroup() {
      contactGroupApi.viewAllContactGroup().then(response => {
        this.resultGroupContact = response
      }).catch(err => {
        this.resultGroupContact = err
      })
    }
  }
};
</script>

