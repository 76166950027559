<template>
  <b-tabs content-class="mt-3">
    <b-tab v-for="item in options"
           :key="item.id"
           :title="item.label"
           @click="onTab(item.id)"
    >
    </b-tab>
  </b-tabs>
</template>

<script>
export default {
  name: "Tab",
  emits: ["update"],
  props: {
    active: {
      type: String,
      default: String,
    },
    options: {
      type: Array,
      default: Array,
    },
  },
  methods: {
    onTab(val) {
      this.$emit("active", val);
    },
  },
};
</script>

<style>
</style>