import service from './sms2ProAxios'

const resourcePath = '/contacts'
export default {
    viewAllContactGroup() {
        return service.apiSms2pro.get(`${resourcePath}`)
            .then(resp => {
                return resp.data
            }).catch(err => {
                throw err
            })
    },
    viewContactGroup(data) {
        return service.apiSms2pro.post(`${resourcePath}/${data?.group_id}/show`)
            .then(resp => {
                return resp.data
            }).catch(err => {
                throw err
            })
    },
    createContactGroup(data) {
        const mapBody = {
            name: data?.name,
        }
        return service.apiSms2pro.post(`${resourcePath}`, mapBody)
            .then(resp => {
                return resp.data
            }).catch(err => {
                throw err
            })
    },
    updateContactGroup(data) {
        const mapBody = {
            name: data?.name,
        }
        return service.apiSms2pro.patch(`${resourcePath}/${data?.group_id}`, mapBody)
            .then(resp => {
                return resp.data
            }).catch(err => {
                throw err
            })
    },
    deleteContactGroup(data) {
        return service.apiSms2pro.delete(`${resourcePath}/${data?.group_id}`)
            .then(resp => {
                return resp.data
            }).catch(err => {
                throw err
            })
    },


}