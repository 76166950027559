<template>
  <div>
    <detail-template
        type-key="header"
        :topic="sms.topic"
        :detail="sms.detail"
        :end-point="sms.apiEndpoint"
        :parameter-items="sms.parameterItems"
    />
    <detail-template
        :topic="create.topic"
        :detail="create.detail"
        :end-point="create.apiEndpoint"
        :parameter-items="create.parameterItems"
    />
    <CodeTemplate
        @click="sendSMS"
        category="otp_createSMS"
        :data="createData"
        :template-config="codeTemplateConfig.createSMS"
        :result="result"
        :last-hint="lastHint"
    />
    <detail-template
        :topic="createVerify.topic"
        :detail="createVerify.detail"
        :end-point="createVerify.apiEndpoint"
        :parameter-items="createVerify.parameterItems"
    />
    <CodeTemplate
        @click="SMSVerify"
        category="otp_viewSMS"
        :data="createVerifyData"
        :template-config="codeTemplateConfig.SMSVerify"
        :result="resultVerify"
    />
    <!-- status sms otp   -->
    <div>
      <h4 class="card-title">Status SMS OTP</h4>
      <label>Response status SMS OTP </label>
      <div>
        <b-table responsive head-variant="light" bordered class="mb-0" :items="statusSmsOtpList" :fields="fields">
        </b-table>
      </div>
    </div>
  </div>
</template>

<script>
import CodeTemplate from "../commonAPI/codeTemplate";
import codeTemplateConfig from "./codeTemplateConfig";
import DetailTemplate from "@/views/smsAPI/commonAPI/detailTemplate";
import smsApi from "@/repository/apiSMS";
import alert from "@/common/alert";
import statusSmsOtp from "@/views/smsAPI/sms/statusSmsApi.json"
export default {
  name: "otpApi",
  components: {
    DetailTemplate,
    CodeTemplate,
  },
  data() {
    return {
      codeTemplateConfig,
      lastHint: {
        topic: {
          title: 'custom_message pattern:',
          style: {
            color: '#D30000',
            margin: 0,
            textAlign: 'left',
            paddingRight: '16px'
          }
        },
        massage: {
          massage_1: 'Message1 ',
          massage_2: 'Message2 ',
          massage_3: 'Message3 ',
          otp: ' {otp} ',
          validity: ' {validity} ',
          refCode: ' {refcode} ',
          style: {
            color: '#008D69'
          }
        }
      },
      sms: {
        topic: 'SMS OTP',
        detail: '',
        apiEndpoint: '/otp-sms/send',
        parameterItems: [
          {
            parameter: "Authorization",
            required: {statusbg: "success", statustype: "Yes"},
            description: "When calling our API, send your api token with the authentication type set as <span style=\"color:#e74c3c\"><code><span style=\"background-color:#dddddd\">Bearer</span></code></span> (Example: <span style=\"color:#e74c3c\"><code><span style=\"background-color:#dddddd\">Authorization: Bearer {api_token}</span></code></span>)",
          },
          {
            parameter: "Accept",
            required: {statusbg: "success", statustype: "Yes"},
            description: "Set to <span style=\"color:#e74c3c\"><code><span style=\"background-color:#dddddd\">application/json</span></code></span>",
          },
        ],
      },
      create: {
        topic: '',
        detail: '',
        apiEndpoint: '',
        parameterItems: [
          {
            parameter: "recipient",
            required: {statusbg: "success", statustype: "Yes"},
            type: "string",
            description: "Number to send message",
          },
          {
            parameter: "sender_name",
            required: {statusbg: "success", statustype: "Yes"},
            type: "string",
            description: "The sender of the message. This can be a telephone number (including country code) or an alphanumeric string. In case of an alphanumeric string, the maximum length is 11 characters.",
          },
          {
            parameter: "ref_code",
            required: {statusbg: "warning", statustype: "Optional"},
            type: "string",
            description: "Ref code is the reference of OTP code in SMS.",
          },
          {
            parameter: "digit",
            required: {statusbg: "warning", statustype: "Optional"},
            type: "number",
            description: "Digit is the number of digits of the 4-6 digit OTP code (default = 4).",
          },
          {
            parameter: "validity",
            required: {statusbg: "warning", statustype: "Optional"},
            type: "number",
            description: "validity is the expiration time of the OTP in minutes (default = 5 minutes).",
          },
          {
            parameter: "custom_message",
            required: {statusbg: "warning", statustype: "Optional"},
            type: "string",
            description: "Define a message that must receive an OTP by specifying {otp} , {validity} , {refcode}",
          },
        ],
      },
      result: {},
      createVerify: {
        topic: 'OTP VERIFY',
        detail: '',
        apiEndpoint: '/otp-sms/verify',
        parameterItems: [
          {
            parameter: "token",
            required: {statusbg: "success", statustype: "Yes"},
            type: "string",
            description: "The token is used to reply.",
          },
          {
            parameter: "otp_code",
            required: {statusbg: "success", statustype: "Yes"},
            type: "string",
            description: "OTP Code",
          },
          {
            parameter: "ref_code",
            required: {statusbg: "warning", statustype: "Optional"},
            type: "string",
            description: "Ref code is the reference of OTP code in SMS.",
          },
        ],

      },
      resultVerify: {},
      createData: {
        fields: [
          {
            label: "recipient",
            field: "recipient",
            placeholder: "Input group recipient",
            // required:"required"
          },
          {
            label: "sender_name",
            field: "sender_name",
            placeholder: "Input sender id",
            // required:"required"
          },
          {
            label: "ref_code",
            field: "ref_code",
            placeholder: "Input ref code",
          },
          {
            label: "digit",
            field: "digit",
            placeholder: "Input digit",
          },
          {
            label: "validity",
            field: "validity",
            placeholder: "Input validity",
          },
          {
            label: "custom_message",
            field: "custom_message",
            placeholder: "Input custom massage",
          },

        ],
      },
      createVerifyData: {
        fields: [
          {
            label: "token",
            field: "token",
            placeholder: "Input token",
          },
          {
            label: "otp_code",
            field: "otp_code",
            placeholder: "Input otp code",
          },
          {
            label: "ref_code",
            field: "ref_code",
            placeholder: "Input ref code",
          }
        ],
      },
      fields: [
        {key: 'code', label: 'CODE'},
        {key: 'description', label: 'Description (EN)',},
        {key: 'descriptionTH', label: 'Description (TH)'},
      ],
      statusSmsOtpList: statusSmsOtp
    };
  },
  methods: {
    sendSMS(param) {
      this.$swal.fire({
        icon: 'warning',
        title: this.$t('alertSMSApi.confirm'),
        text: this.$t('alertSMSApi.detail'),
        showCloseButton: true,
        showCancelButton: true,
        confirmButtonColor: '#EA5455',
        cancelButtonColor: '#7367F0',
        cancelButtonText: this.$t('button.cancel'),
        confirmButtonText: this.$t('button.send'),
        allowOutsideClick: false
      }).then((result) => {
        if (result.isConfirmed) {
          smsApi.sendOTP(param).then(response => {
            if (response.status === 'success') {
              alert.success(this)
              this.result = response
              this.$emit('output')
            }
          }).catch(err => {
            if (err?.code == "400" && err?.message === "Customer Credit Not Enough") {
              const current_plan_id = localStorage.getItem('current_plan_id')
              alert.creditIsInsufficient(this, err?.message, `/Subscriptions/Package?current=${current_plan_id}`)
            } else {
              alert.fail(this, err?.message)
            }
            this.result = err
          })
        }
      })
    },
    SMSVerify(param) {
      smsApi.SMSVerify(param).then(response => {
        if (response.status === 'success') {
          alert.success(this)
          this.resultVerify = response
        }
      }).catch(err => {
        if (err?.code == "400" && err?.message === "Customer Credit Not Enough") {
          const current_plan_id = localStorage.getItem('current_plan_id')
          alert.creditIsInsufficient(this, err?.message, `/Subscriptions/Package?current=${current_plan_id}`)
        } else {
          alert.fail(this, err?.message)
        }
        this.resultVerify = err
      })
    },
  }
};
</script>

