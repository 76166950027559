const Authorization = localStorage.getItem('smsToken')
const ApiURL = 'https://client.sms2pro.com/api/v3'
const createContact = {
    curl: `curl -X POST '` + ApiURL + `/contacts/{group_id}/store
     -H Authorization: Bearer ` + Authorization + `
     -d 'phone' : {phone}
     -d 'first_name' : '{first_name}'
     -d 'last_name' : '{last_name}'`,
    php: `<?php //Support version greater than or equal 7.X.X 
     $curl = curl_init();
     curl_setopt_array($curl, array(
            CURLOPT_URL => '` + ApiURL + `/contacts/{group_id}/store', 
            CURLOPT_RETURNTRANSFER => true,
            CURLOPT_ENCODING => '',
            CURLOPT_MAXREDIRS => 10,
            CURLOPT_TIMEOUT => 0,
            CURLOPT_FOLLOWLOCATION => true,
            CURLOPT_HTTP_VERSION => CURL_HTTP_VERSION_1_1,
            CURLOPT_CUSTOMREQUEST => 'POST',
            CURLOPT_HTTPHEADER => array(
            "Content-Type: application/json",
            "Authorization: Bearer ` + Authorization + `"
            ),
            CURLOPT_POSTFIELDS =>json_encode(array(
            "phone" => "{phone}",
            "first_name" => "{first_name}",
            "last_name" => "{last_name}"
            )),
         ));
     $response = curl_exec($curl);
     curl_close($curl);
     echo $response;`,
    nodejs: `var axios = require('axios');
var config = {
    method: 'post',
    url: '` + ApiURL + `/contacts/{group_id}/store', 
    headers: {
        "Content-Type: application/json",
        "Authorization: Bearer ` + Authorization + ` "
    },
    data:JSON.stringify({
      'phone' : {phone}
      'first_name' : '{first_name}'
      'last_name' : '{last_name}'
    })
};
axios(config).then(function (response) {
    console.log(JSON.stringify(response.data));
}).catch(function (error) {
    console.log(error);
});`,
    python: `import requests
    url: '` + ApiURL + `/contacts/{group_id}/store', 
headers = {
    "Accept": "application/json",
    "Content-Type": "application/json",
    "Authorization": "Bearer ` + Authorization + ` "
}
payload = {
 'phone' : {phone}
 'first_name' : '{first_name}'
 'last_name' : '{last_name}'
}
response = requests.request("POST", url, json=payload, headers=headers)
print(response.text)`,
};
const viewContact = {
    curl: `curl -X POST '` + ApiURL + `/contacts/{group_id}/search/{uid}
     -H Authorization: Bearer ` + Authorization,
    php: `<?php //Support version greater than or equal 7.X.X 
     $curl = curl_init();
     curl_setopt_array($curl, array(
            CURLOPT_URL => '` + ApiURL + `/contacts/{group_id}/search/{uid}', 
            CURLOPT_RETURNTRANSFER => true,
            CURLOPT_ENCODING => '',
            CURLOPT_MAXREDIRS => 10,
            CURLOPT_TIMEOUT => 0,
            CURLOPT_FOLLOWLOCATION => true,
            CURLOPT_HTTP_VERSION => CURL_HTTP_VERSION_1_1,
            CURLOPT_CUSTOMREQUEST => 'POST',
            CURLOPT_HTTPHEADER => array(
            "Content-Type: application/json",
            "Authorization: Bearer ` + Authorization + `"
            ),
         ));
     $response = curl_exec($curl);
     curl_close($curl);
     echo $response;`,
    nodejs: `var axios = require('axios');
var config = {
    method: 'post',
    url: '` + ApiURL + `/contacts/{group_id}/search/{uid}', 
    headers: {
        "Content-Type: application/json",
        "Authorization: Bearer ` + Authorization + ` "
    },
};
axios(config).then(function (response) {
    console.log(JSON.stringify(response.data));
}).catch(function (error) {
    console.log(error);
});`,
    python: `import requests
    url: '` + ApiURL + `/contacts/{group_id}/search/{uid}', 
headers = {
    "Accept": "application/json",
    "Content-Type": "application/json",
    "Authorization": "Bearer ` + Authorization + ` "
}
response = requests.request("POST", url, headers=headers)
print(response.text)`,
};
const updateContact = {
    curl: `curl -X PATCH '` + ApiURL + `/contacts/{group_id}/update/{uid}
     -H Authorization: Bearer ` + Authorization + `
     -d 'phone' : {phone}
     -d 'first_name' : '{first_name}'
     -d 'last_name' : '{last_name}'`,
    php: `<?php //Support version greater than or equal 7.X.X 
     $curl = curl_init();
     curl_setopt_array($curl, array(
            CURLOPT_URL => '` + ApiURL + `/contacts/{group_id}/update/{uid}', 
            CURLOPT_RETURNTRANSFER => true,
            CURLOPT_ENCODING => '',
            CURLOPT_MAXREDIRS => 10,
            CURLOPT_TIMEOUT => 0,
            CURLOPT_FOLLOWLOCATION => true,
            CURLOPT_HTTP_VERSION => CURL_HTTP_VERSION_1_1,
            CURLOPT_CUSTOMREQUEST => 'PATCH',
            CURLOPT_HTTPHEADER => array(
            "Content-Type: application/json",
            "Authorization: Bearer ` + Authorization + `"
            ),
            CURLOPT_POSTFIELDS =>json_encode(array( 
            'phone' : {phone}
            'first_name' : '{first_name}'
            'last_name' : '{last_name}'
            )),
         ));
     $response = curl_exec($curl);
     curl_close($curl);
     echo $response;`,
    nodejs: `var axios = require('axios');
var config = {
    method: 'PATCH',
    url: '` + ApiURL + `/contacts/{group_id}/update/{uid}', 
    headers: {
        "Content-Type: application/json",
        "Authorization: Bearer ` + Authorization + ` "
    },
    data:JSON.stringify({
        'phone' : {phone}
        'first_name' : '{first_name}'
        'last_name' : '{last_name}'
    })
};
axios(config).then(function (response) {
    console.log(JSON.stringify(response.data));
}).catch(function (error) {
    console.log(error);
});`,
    python: `import requests
    url: '` + ApiURL + `/contacts/{group_id}/update/{uid}', 
headers = {
    "Accept": "application/json",
    "Content-Type": "application/json",
    "Authorization": "Bearer ` + Authorization + ` "
}
payload = {
    'phone' : {phone}
    'first_name' : '{first_name}'
    'last_name' : '{last_name}'
}
response = requests.request("PATCH", url, json=payload, headers=headers)
print(response.text)`,
};
const deleteContact = {
    curl: `curl -X DELETE '` + ApiURL + `/contacts/{group_id}/delete/{uid}
     -H Authorization: Bearer ` + Authorization,
    php: `<?php //Support version greater than or equal 7.X.X 
     $curl = curl_init();
     curl_setopt_array($curl, array(
            CURLOPT_URL => '` + ApiURL + `/contacts/{group_id}/delete/{uid}', 
            CURLOPT_RETURNTRANSFER => true,
            CURLOPT_ENCODING => '',
            CURLOPT_MAXREDIRS => 10,
            CURLOPT_TIMEOUT => 0,
            CURLOPT_FOLLOWLOCATION => true,
            CURLOPT_HTTP_VERSION => CURL_HTTP_VERSION_1_1,
            CURLOPT_CUSTOMREQUEST => 'DELETE',
            CURLOPT_HTTPHEADER => array(
            "Content-Type: application/json",
            "Authorization: Bearer ` + Authorization + `"
            ),
         ));
     $response = curl_exec($curl);
     curl_close($curl);
     echo $response;`,
    nodejs: `var axios = require('axios');
var config = {
    method: 'DELETE',
    url: '` + ApiURL + `/contacts/{group_id}/delete/{uid}', 
    headers: {
        "Content-Type: application/json",
        "Authorization: Bearer ` + Authorization + ` "
    },
};
axios(config).then(function (response) {
    console.log(JSON.stringify(response.data));
}).catch(function (error) {
    console.log(error);
});`,
    python: `import requests
    url: '` + ApiURL + `/contacts/{group_id}/delete/{uid}', 
headers = {
    "Accept": "application/json",
    "Content-Type": "application/json",
    "Authorization": "Bearer ` + Authorization + ` "
}
response = requests.request("DELETE", url, headers=headers)
print(response.text)`,
};
const viewAllContact = {
    curl: `curl -X GET '` + ApiURL + `/contacts/{group_id}/all
     -H Authorization: Bearer ` + Authorization,
    php: `<?php //Support version greater than or equal 7.X.X 
     $curl = curl_init();
     curl_setopt_array($curl, array(
            CURLOPT_URL => '` + ApiURL + `/contacts/{group_id}/all', 
            CURLOPT_RETURNTRANSFER => true,
            CURLOPT_ENCODING => '',
            CURLOPT_MAXREDIRS => 10,
            CURLOPT_TIMEOUT => 0,
            CURLOPT_FOLLOWLOCATION => true,
            CURLOPT_HTTP_VERSION => CURL_HTTP_VERSION_1_1,
            CURLOPT_CUSTOMREQUEST => 'GET',
            CURLOPT_HTTPHEADER => array(
            "Content-Type: application/json",
            "Authorization: Bearer ` + Authorization + `"
            ),
         ));
     $response = curl_exec($curl);
     curl_close($curl);
     echo $response;`,
    nodejs: `var axios = require('axios');
var config = {
    method: 'GET',
    url: '` + ApiURL + `/contacts/{group_id}/all', 
    headers: {
        "Content-Type: application/json",
        "Authorization: Bearer ` + Authorization + ` "
    },
};
axios(config).then(function (response) {
    console.log(JSON.stringify(response.data));
}).catch(function (error) {
    console.log(error);
});`,
    python: `import requests
    url: '` + ApiURL + `/contacts/{group_id}/all', 
headers = {
    "Accept": "application/json",
    "Content-Type": "application/json",
    "Authorization": "Bearer ` + Authorization + ` "
}
response = requests.request("GET", url, headers=headers)
print(response.text)`,
};
export default {
    createContact,
    viewContact,
    updateContact,
    deleteContact,
    viewAllContact
};
