import axios from "axios";
import {BToast} from 'bootstrap-vue'

const apiSms2pro = axios.create({
    baseURL: '/sms-api',
});

function interceptor(axiosConfig) {
    axiosConfig.defaults.headers.common["Accept-Language"] = 'th';
    axiosConfig.defaults.headers.common["Authorization"] = `Bearer ${localStorage.getItem("smsToken")}`;
    axiosConfig.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
    axiosConfig.interceptors.request.use((config) => {
            const accessToken = localStorage.getItem("smsToken");
            config.headers.Authorization = `Bearer ${accessToken}`;
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );

    axiosConfig.interceptors.response.use(
        (response) => response, async (error) => {
            const status = error.response ? error.response.status : null;
            const message = error.response ? error.response : null;
            const originalRequest = error.config;
            if (status === 401 && !originalRequest._retry) {
                originalRequest._retry = true;
                originalRequest.headers.Authorization = `Bearer ${localStorage.getItem("smsToken")}`;
                return axiosConfig(originalRequest)
            } else if (status === 429) {
                let bootStrapToaster = new BToast();
                bootStrapToaster.$bvToast.toast("Too many requests, please try again later.", {
                    title: 'Notice!',
                    toaster: "b-toaster-top-right",
                    solid: true,
                    variant: 'danger',
                    appendToast: false
                })
            }
            return Promise.reject(error);
        }
    );
}

interceptor(apiSms2pro);

export default {
    apiSms2pro,
};













