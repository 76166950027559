import {store} from '@/store'

const Authorization = localStorage.getItem('smsToken')
const ApiURL = window.location.origin + '/sms-api'
const createSMS = {
    curl: `curl -X POST '` + ApiURL + `/campaign-sms/send'
     -H Authorization: Bearer ` + Authorization + `
     -d 'name' : '{name}'
     -d 'recipients' : '{recipients}'
     -d 'sender_name' : '{sender_name}'
     -d 'message' : '{message}'
     -d 'send_date' : '{send_date}'`,
    php: `<?php //Support version greater than or equal 7.X.X 
     $curl = curl_init();
     curl_setopt_array($curl, array(
            CURLOPT_URL => '` + ApiURL + `/campaign-sms/send', 
            CURLOPT_RETURNTRANSFER => true,
            CURLOPT_ENCODING => '',
            CURLOPT_MAXREDIRS => 10,
            CURLOPT_TIMEOUT => 0,
            CURLOPT_FOLLOWLOCATION => true,
            CURLOPT_HTTP_VERSION => CURL_HTTP_VERSION_1_1,
            CURLOPT_CUSTOMREQUEST => 'POST',
            CURLOPT_HTTPHEADER => array(
            "Content-Type: application/json",
            "Authorization: Bearer ` + Authorization + `"
            ),
            CURLOPT_POSTFIELDS =>json_encode(array(
            "name" => "{name}",
            "recipients" => "{recipients}",
            "sender_name" => "{sender_name}",
            "message" => "{message}",
            "send_date" => "{send_date}"
            )),
         ));
     $response = curl_exec($curl);
     curl_close($curl);
     echo $response;`,
    nodejs: `var axios = require('axios');
var config = {
    method: 'post',
    url: '` + ApiURL + `/campaign-sms/send', 
    headers: {
        "Content-Type: application/json",
        "Authorization: Bearer ` + Authorization + ` "
    },
    data:JSON.stringify({
        'name' : '{name}'
        'recipients' : '{recipients}'
        'sender_name' : '{sender_name}'
        'message' : '{message}'
        'send_date' : '{send_date}'
    })
};
axios(config).then(function (response) {
    console.log(JSON.stringify(response.data));
}).catch(function (error) {
    console.log(error);
});`,
    python: `import requests
    url: '` + ApiURL + `/campaign-sms/send', 
headers = {
    "Accept": "application/json",
    "Content-Type": "application/json",
    "Authorization": "Bearer ` + Authorization + ` "
}
payload = {
    'name' : '{name}'
    'recipients' : '{recipients}'
    'sender_name' : '{sender_name}'
    'message' : '{message}'
    'send_date' : '{send_date}'
}
response = requests.request("POST", url, json=payload, headers=headers)
print(response.text)`,
};
const viewSMS = {
    curl: `curl -X GET '` + ApiURL + `/campaign-sms/get/{uid}'
     -H Authorization: Bearer ` + Authorization,
    php: `<?php //Support version greater than or equal 7.X.X 
     $curl = curl_init();
     curl_setopt_array($curl, array(
            CURLOPT_URL => '` + ApiURL + `/campaign-sms/get/{uid}', 
            CURLOPT_RETURNTRANSFER => true,
            CURLOPT_ENCODING => '',
            CURLOPT_MAXREDIRS => 10,
            CURLOPT_TIMEOUT => 0,
            CURLOPT_FOLLOWLOCATION => true,
            CURLOPT_HTTP_VERSION => CURL_HTTP_VERSION_1_1,
            CURLOPT_CUSTOMREQUEST => 'GET',
            CURLOPT_HTTPHEADER => array(
            "Content-Type: application/json",
            "Authorization: Bearer ` + Authorization + `"
            ),
         ));
     $response = curl_exec($curl);
     curl_close($curl);
     echo $response;`,
    nodejs: `var axios = require('axios');
var config = {
    method: 'GET',
    url: '` + ApiURL + `/campaign-sms/get/{uid}', 
    headers: {
        "Content-Type: application/json",
        "Authorization: Bearer ` + Authorization + ` "
    },
};
axios(config).then(function (response) {
    console.log(JSON.stringify(response.data));
}).catch(function (error) {
    console.log(error);
});`,
    python: `import requests
    url: '` + ApiURL + `/campaign-sms/get/{uid}', 
headers = {
    "Accept": "application/json",
    "Content-Type": "application/json",
    "Authorization": "Bearer ` + Authorization + ` "
}
response = requests.request("GET", url, headers=headers)
print(response.text)`,
};
const viewAllSMS = {
    curl: `curl -X GET '` + ApiURL + `/campaign-sms/list
     -H Authorization: Bearer ` + Authorization,
    php: `<?php //Support version greater than or equal 7.X.X 
     $curl = curl_init();
     curl_setopt_array($curl, array(
            CURLOPT_URL => '` + ApiURL + `/campaign-sms/list', 
            CURLOPT_RETURNTRANSFER => true,
            CURLOPT_ENCODING => '',
            CURLOPT_MAXREDIRS => 10,
            CURLOPT_TIMEOUT => 0,
            CURLOPT_FOLLOWLOCATION => true,
            CURLOPT_HTTP_VERSION => CURL_HTTP_VERSION_1_1,
            CURLOPT_CUSTOMREQUEST => 'GET',
            CURLOPT_HTTPHEADER => array(
            "Content-Type: application/json",
            "Authorization: Bearer ` + Authorization + `"
            ),
         ));
     $response = curl_exec($curl);
     curl_close($curl);
     echo $response;`,
    nodejs: `var axios = require('axios');
var config = {
    method: 'GET',
    url: '` + ApiURL + `/campaign-sms/list', 
    headers: {
        "Content-Type: application/json",
        "Authorization: Bearer ` + Authorization + ` "
    },
};
axios(config).then(function (response) {
    console.log(JSON.stringify(response.data));
}).catch(function (error) {
    console.log(error);
});`,
    python: `import requests
    url: '` + ApiURL + `/campaign-sms/list', 
headers = {
    "Accept": "application/json",
    "Content-Type": "application/json",
    "Authorization": "Bearer ` + Authorization + ` "
}
response = requests.request("GET", url, headers=headers)
print(response.text)`,
};
export default {
    createSMS,
    viewSMS,
    viewAllSMS
};
