import service from './sms2ProAxios'

const resourcePath = '/profile'
export default {
    viewProfile() {
        return service.apiSms2pro.get(`${resourcePath}/get`)
            .then(resp => {
                return resp.data
            }).catch(err => {
                throw err.response.data
            })
    },
    viewSMSUnit() {
        return service.apiSms2pro.get(`${resourcePath}/get-balance`)
            .then(resp => {
                return resp.data
            }).catch(err => {
                throw err.response.data
            })
    },
}