const Authorization = localStorage.getItem('smsToken')
const ApiURL = window.location.origin + '/sms-api'
const createSMS = {
    curl: `curl -X POST '` + ApiURL + `/otp-sms/send'
     -H Authorization: Bearer ` + Authorization + `
     -d 'recipient' : '{recipient}'
     -d 'sender_name' : '{sender_name}'
     -d 'ref_code' : '{ref_code}'
     -d 'digit' : {digit}
     -d 'validity' : {validity}
     -d 'custom_message' : '{custom_message}'
     `,
    php: `<?php //Support version greater than or equal 7.X.X 
     $curl = curl_init();
     curl_setopt_array($curl, array(
            CURLOPT_URL => '` + ApiURL + `/otp-sms/send', 
            CURLOPT_RETURNTRANSFER => true,
            CURLOPT_ENCODING => '',
            CURLOPT_MAXREDIRS => 10,
            CURLOPT_TIMEOUT => 0,
            CURLOPT_FOLLOWLOCATION => true,
            CURLOPT_HTTP_VERSION => CURL_HTTP_VERSION_1_1,
            CURLOPT_CUSTOMREQUEST => 'POST',
            CURLOPT_HTTPHEADER => array(
            "Content-Type: application/json",
            "Authorization: Bearer ` + Authorization + `"
            ),
            CURLOPT_POSTFIELDS =>json_encode(array(
            "recipient" => "{recipient}",
            "sender_name" => "{sender_name}",
            "ref_code" => "{ref_code}",
            "digit" => "{digit}",
            "validity" => "{validity}",
            "custom_message" => "{custom_message}"
            )),
         ));
     $response = curl_exec($curl);
     curl_close($curl);
     echo $response;`,
    nodejs: `var axios = require('axios');
var config = {
    method: 'post',
    url: '` + ApiURL + `/otp-sms/send', 
    headers: {
        "Content-Type: application/json",
        "Authorization: Bearer ` + Authorization + ` "
    },
    data:JSON.stringify({
        'recipient' : '{recipient}'
        'sender_name' : '{sender_name}'
        'ref_code' : '{ref_code}'
        'digit' : '{digit}'
        'validity' : '{validity}'
        'custom_message' : '{custom_message}'
    })
};
axios(config).then(function (response) {
    console.log(JSON.stringify(response.data));
}).catch(function (error) {
    console.log(error);
});`,
    python: `import requests
    url: '` + ApiURL + `/otp-sms/send', 
headers = {
    "Accept": "application/json",
    "Content-Type": "application/json",
    "Authorization": "Bearer ` + Authorization + ` "
}
payload = {
    'recipient' : '{recipient}'
    'sender_name' : '{sender_name}'
    'ref_code' : '{ref_code}'
    'digit' : '{digit}'
    'validity' : '{validity}'
    'custom_message' : '{custom_message}'
}
response = requests.request("POST", url, json=payload, headers=headers)
print(response.text)`,
};
const SMSVerify = {
    curl: `curl -X POST '` + ApiURL + `/otp-sms/verify'
     -H Authorization: Bearer ` + Authorization + `
     -d 'token' : '{token}'
     -d 'otp_code' : '{otp_code}'
     -d 'ref_code' : '{ref_code}'`,
    php: `<?php //Support version greater than or equal 7.X.X 
     $curl = curl_init();
     curl_setopt_array($curl, array(
            CURLOPT_URL => '` + ApiURL + `/otp-sms/verify', 
            CURLOPT_RETURNTRANSFER => true,
            CURLOPT_ENCODING => '',
            CURLOPT_MAXREDIRS => 10,
            CURLOPT_TIMEOUT => 0,
            CURLOPT_FOLLOWLOCATION => true,
            CURLOPT_HTTP_VERSION => CURL_HTTP_VERSION_1_1,
            CURLOPT_CUSTOMREQUEST => 'POST',
            CURLOPT_HTTPHEADER => array(
            "Content-Type: application/json",
            "Authorization: Bearer ` + Authorization + `"
            ),
            CURLOPT_POSTFIELDS =>json_encode(array(
            "token" => "{token}",
            "otp_code" => "{otp_code}",
            "ref_code" => "{ref_code}"
            )),
         ));
     $response = curl_exec($curl);
     curl_close($curl);
     echo $response;`,
    nodejs: `var axios = require('axios');
var config = {
    method: 'post',
    url: '` + ApiURL + `/otp-sms/verify', 
    headers: {
        "Content-Type: application/json",
        "Authorization: Bearer ` + Authorization + ` "
    },
    data:JSON.stringify({
         'token' : '{token}'
         'otp_code' : '{otp_code}'
         'ref_code' : '{ref_code}'
    })
};
axios(config).then(function (response) {
    console.log(JSON.stringify(response.data));
}).catch(function (error) {
    console.log(error);
});`,
    python: `import requests
    url: '` + ApiURL + `/otp-sms/verify', 
headers = {
    "Accept": "application/json",
    "Content-Type": "application/json",
    "Authorization": "Bearer ` + Authorization + ` "
}
payload = {
    'token' : '{token}'
    'otp_code' : '{otp_code}'
    'ref_code' : '{ref_code}'
}
response = requests.request("POST", url, json=payload, headers=headers)
print(response.text)`,
};
export default {
    createSMS,
    SMSVerify
};
