import service from './sms2ProAxios'

const resourcePath = '/contacts'
export default {
    viewAllContact(data) {
        return service.apiSms2pro.post(`${resourcePath}/${data.group_id}/all`)
            .then(resp => {
                return resp.data
            }).catch(err => {
                throw err
            })
    },
    viewContact(data) {
        return service.apiSms2pro.post(`${resourcePath}/${data.group_id}/search/${data.uid}`)
            .then(resp => {
                return resp.data
            }).catch(err => {
                throw err
            })
    },
    createContact(data) {
        const mapBody = {
            phone: Number(data?.phone),
            first_name: data?.first_name,
            last_name: data?.last_name,
        }
        return service.apiSms2pro.post(`${resourcePath}/${data.group_id}/store`, mapBody)
            .then(resp => {
                return resp.data
            }).catch(err => {
                throw err
            })
    },
    updateContact(data) {
        const mapBody = {
            phone: parseInt(data?.phone),
            first_name: data?.first_name,
            last_name: data?.last_name,
        }
        return service.apiSms2pro.patch(`${resourcePath}/${data.group_id}/update/${data.uid}`, mapBody)
            .then(resp => {
                return resp.data
            }).catch(err => {
                throw err
            })
    },
    deleteContact(data) {
        return service.apiSms2pro.delete(`${resourcePath}/${data.group_id}/delete/${data.uid}`)
            .then(resp => {
                return resp.data
            }).catch(err => {
                throw err
            })
    },


}