const Authorization = localStorage.getItem('smsToken')
const ApiURL = window.location.origin + '/sms-api'
const viewSMSUnit = {
    curl: `curl -X GET '` + ApiURL + `/profile/get-balance'
     -H Authorization: Bearer ` + Authorization,
    php: `<?php //Support version greater than or equal 7.X.X 
     $curl = curl_init();
     curl_setopt_array($curl, array(
            CURLOPT_URL => '` + ApiURL + `/profile/get-balance', 
            CURLOPT_RETURNTRANSFER => true,
            CURLOPT_ENCODING => '',
            CURLOPT_MAXREDIRS => 10,
            CURLOPT_TIMEOUT => 0,
            CURLOPT_FOLLOWLOCATION => true,
            CURLOPT_HTTP_VERSION => CURL_HTTP_VERSION_1_1,
            CURLOPT_CUSTOMREQUEST => 'GET',
            CURLOPT_HTTPHEADER => array(
            "Content-Type: application/json",
            "Authorization: Bearer ` + Authorization + `"
            ),
         ));
     $response = curl_exec($curl);
     curl_close($curl);
     echo $response;`,
    nodejs: `var axios = require('axios');
var config = {
    method: 'GET',
    url: '` + ApiURL + `/profile/get-balance', 
    headers: {
        "Content-Type: application/json",
        "Authorization: Bearer ` + Authorization + ` "
    },
};
axios(config).then(function (response) {
    console.log(JSON.stringify(response.data));
}).catch(function (error) {
    console.log(error);
});`,
    python: `import requests
    url: '` + ApiURL + `/profile/get-balance', 
headers = {
    "Accept": "application/json",
    "Content-Type": "application/json",
    "Authorization": "Bearer ` + Authorization + ` "
}
response = requests.request("GET", url, headers=headers)
print(response.text)`,
};
const viewProfile = {
    curl: `curl -X GET '` + ApiURL + `/profile/get'
     -H Authorization: Bearer ` + Authorization,
    php: `<?php //Support version greater than or equal 7.X.X 
     $curl = curl_init();
     curl_setopt_array($curl, array(
            CURLOPT_URL => '` + ApiURL + `/me', 
            CURLOPT_RETURNTRANSFER => true,
            CURLOPT_ENCODING => '',
            CURLOPT_MAXREDIRS => 10,
            CURLOPT_TIMEOUT => 0,
            CURLOPT_FOLLOWLOCATION => true,
            CURLOPT_HTTP_VERSION => CURL_HTTP_VERSION_1_1,
            CURLOPT_CUSTOMREQUEST => 'GET',
            CURLOPT_HTTPHEADER => array(
            "Content-Type: application/json",
            "Authorization: Bearer ` + Authorization + `"
            ),
         ));
     $response = curl_exec($curl);
     curl_close($curl);
     echo $response;`,
    nodejs: `var axios = require('axios');
var config = {
    method: 'GET',
    url: '` + ApiURL + `/profile/get', 
    headers: {
        "Content-Type: application/json",
        "Authorization: Bearer ` + Authorization + ` "
    },
};
axios(config).then(function (response) {
    console.log(JSON.stringify(response.data));
}).catch(function (error) {
    console.log(error);
});`,
    python: `import requests
    url: '` + ApiURL + `/profile/get', 
headers = {
    "Accept": "application/json",
    "Content-Type": "application/json",
    "Authorization": "Bearer ` + Authorization + ` "
}
response = requests.request("GET", url, headers=headers)
print(response.text)`,
};
export default {
    viewSMSUnit,
    viewProfile
};
